import {get_tokens, remove_token} from './datapipeModel';

import messages from 'utils/messagesComponent';
import {copyUrlContent} from 'utils/copyUrl';

import addComponent from '../../addUser/addUserComponent';
import {remove_user} from "../users/usersModel";


export default datapipeComponent;

let datapipeComponent = {
    controller(){
        let ctrl = {
            tokens:m.prop(),
            loaded:false,
            col_error:m.prop(''),
            remove};

        function remove(token_id){
            messages.confirm({header:'Delete token ' + token_id, content:'Are you sure?'})
                .then(response => {
                    if (response)
                        remove_token(token_id)
                            .then(()=> load())
                            .catch(error => {
                                ctrl.col_error(error.message);
                            })
                            .then(m.redraw);
                });
        }

        function load() {
            get_tokens()
                .then(response =>ctrl.tokens(response.tokens))
                .then(()=>ctrl.loaded = true)
                .catch(error => {
                    ctrl.col_error(error.message);
                }).then(m.redraw);
        }
        load();
        return ctrl;
    },
    view(ctrl){
        return  !ctrl.loaded
            ?
            m('.loader')
            :
            m('.container.sharing-page', [
                m('.row',[
                    m('.col-sm-12', [
                        m('h3', 'DataPipe')
                    ])
                ]),
                m('table', {class:'table table-striped table-hover'}, [
                    m('thead', [
                        m('tr', [
                            m('th', 'Token'),
                            m('th', 'Domain'),
                            m('th',  'Uses'),
                            m('th',  'First use'),
                            m('th',  'Last use'),
                            m('th',  'Debug'),
                            m('th',  'Remove')
                        ])
                    ]),
                    m('tbody', [
                        ctrl.tokens().map(token => m('tr', [
                            m('td', token._id),
                            m('td', token.domain),
                            m('td', token.uses),
                            m('td', token.first_use),
                            m('td', token.last_use),
                            m('td', token.debug ? 'Debug' : ''),
                            m('td', m('button.btn.btn-danger', {onclick:()=>ctrl.remove(token._id)}, 'Remove'))
                        ]))
                    ]),
                ])
            ]);
    }
};

